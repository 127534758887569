body {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.oleo {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgb(21, 14, 92);
    overflow: hidden;
}

@keyframes moveTruck {
    0% {
        transform: translateX(-20vw);
    }

    100% {
        transform: translateX(110vw);
    }
}

.truck {
    width: 20vw;
    height: 12vw;
    position: absolute;
    bottom: 0;
    animation: moveTruck 20s linear infinite;
    z-index: 4;
}

.cab {
    background-color: white;
    width: 8vw;
    height: 7.2vw;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0px 30px 0px 0;
}

.windshield {
    background-color: silver;
    position: absolute;
    width: 60%;
    height: 50%;
    right: 0;
    top: 20%;
    border-radius: 0 5px 5px 0;
}

.bed {
    background-color: white;
    width: 20vw;
    height: 2.4vw;
    position: relative;
    top: 7.2vw;
    border-radius: 0pxpx 0px 0px 0px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.wheel {
    background-color: rgba(0, 0, 0, 0);
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 1000px;
    border: 1.68vw solid black;
    border-width: 1.4vw;
    position: absolute;
    bottom: 0;
    animation: spin 4s linear infinite;
}

.wheel.one {
    right: 5%;
}

.wheel.two {
    right: 55%;
}

.wheel.three {
    right: 75%;
}

.verticalBeam {
    width: 25%;
    background-color: black;
    height: 100%;
    position: absolute;
    left: 37%;
}

.horizontalBeam {
    width: 100%;
    background-color: black;
    height: 26%;
    position: absolute;
    top: 37%;
}

.mixer {
    background-color: rgb(214, 58, 64);
    width: 59%;
    height: 50%;
    position: absolute;
    top: 10%;
    left: 0%
}



.building {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 10vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.wall {
    height: 10vw;
    width: 20vw;
    background-color: rgb(94, 30, 83);
    position: relative;
}

.window {
    height: 4vw;
    width: 4vw;
    position: absolute;
    top: 3vw;
    bottom: 3vw;
    border-radius: 199px 199px 0 0;
    background-color: lightyellow;
}

.window.one {
    left: 4vw;
}

.window.two {
    left: 12vw;
}

.storey {
    display: flex;
}




.moon {
    background-color: rgb(246, 241, 213);
    width: 20vw;
    height: 20vw;
    border-radius: 1000px;
    position: absolute;
    bottom: 25vw;
    left: 3vw;
}



.crane {
    position: absolute;
    right: 20vw;
    bottom: 0;
}

.mast {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mastBox {
    width: 2.5vw;
    height: 2.5vw;
    border: 1vw solid rgb(244, 99, 60);
    border-width: 0.5vw 1vw;
}

.upperFoundation {
    height: 0.9vw;
    width: 5.5vw;
    margin-left: -0.5vw;
    border-bottom: 0.1vw solid black;
    background-color: rgb(214, 58, 64);
}

.lowerFoundation {
    height: 2vw;
    width: 10vw;
    margin-left: -3vw;
    background-color: rgb(214, 58, 64);
}

.jib {
    display: flex;
    margin-left: -49.5vw;
    margin-bottom: -10.5vw;
}

.counterWeight {
    width: 2.4vw;
    height: 7vw;
    border-left: 0.1vw solid black;
    background-color: rgb(214, 58, 64);
}

@keyframes moveTrolley {
    0% {
        transform: translateX(0vw);
    }

    50% {
        transform: translateX(12vw);
    }

    100% {
        transform: translateX(0vw);
    }

}

.trolley {
    width: 4vw;
    height: 1vw;
    background-color: black;
    position: absolute;
    margin-left: -42vw;
    margin-top: 3vw;
    animation: moveTrolley 20s linear infinite;
}

.hoist {
    width: 0.5vw;
    height: 10vw;
    margin-left: 1.75vw;
    background-color: black;
}

@keyframes hoistWallSwing {
    0% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(-5deg);
    }
}

.hoistWall {
    margin-top: -1vw;
    margin-left: -8vw;
    animation-name: hoistWallSwing;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

.hoistWall .window {
    background-color: black;
}

.hideOnMedium {

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {}

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {}

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        display: none;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {}

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {}
}


.board {
    width: 20vw;
    height: 10vw;
    background-color: burlywood;
    border-radius: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    position: absolute;
    bottom: 5vw;
    right: 2vw;
    z-index: 6;
    justify-items: center;
}

.post {
    width: 2vw;
    height: 17vw;
    position: absolute;
    bottom: 0;
    right: 10vw;
    background-color: saddlebrown;
    z-index: 6;
}

h1 {
    font-family: "Lucida Console", "Courier New", monospace;
}

@media all and (min-width: 50px) {
    h1 {
        font-size: 1.1em;
    }
}

@media all and (min-width: 100px) {
    h1 {
        font-size: 1.2em;
    }
}

@media all and (min-width: 200px) {
    h1 {
        font-size: 1.4em;
    }
}

@media all and (min-width: 300px) {
    h1 {
        font-size: 1.6em;
    }
}

@media all and (min-width: 400px) {
    h1 {
        font-size: 1.8em;
    }
}

@media all and (min-width: 500px) {
    h1 {
        font-size: 2.0em;
    }
}

@media all and (min-width: 600px) {
    h1 {
        font-size: 2.2em;
    }
}

@media all and (min-width: 700px) {
    h1 {
        font-size: 2.4em;
    }
}

@media all and (min-width: 768px) {
    h1 {
        font-size: 0.8em;
    }
}

@media all and (min-width: 900px) {
    h1 {
        font-size: 0.8em;
    }
}

@media all and (min-width: 1000px) {
    h1 {
        font-size: 1.0em;
    }
}

@media all and (min-width: 1100px) {
    h1 {
        font-size: 1.2em;
    }
}

@media all and (min-width: 1200px) {
    h1 {
        font-size: 1.4em;
    }
}

@media all and (min-width: 1300px) {
    h1 {
        font-size: 1.6em;
    }
}

@media all and (min-width: 1400px) {
    h1 {
        font-size: 1.8em;
    }
}

@media all and (min-width: 1500px) {
    h1 {
        font-size: 2.0em;
    }
}

@media all and (min-width: 1600px) {
    h1 {
        font-size: 2.2em;
    }
}

@media all and (min-width: 1700px) {
    h1 {
        font-size: 2.4em;
    }
}

@media all and (min-width: 1800px) {
    h1 {
        font-size: 2.6em;
    }
}






.board.mobile {
    position: absolute;
    top: 20px;
    left: 10vw;
    width: 80vw;
    height: 40vw;
    max-height: 200px;
    text-align: center;
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
    .desktop {
        display: none;
    }
}





/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
    .mobile {
        display: none;
    }
}